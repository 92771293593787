import React from 'react';

import Layout from '../components/Layout';

//const IndexPage = () => (
 // <Layout fullMenu>
const IndexPage = () => <Layout fullMenu>
    <article id="main">
      <header>
        <h2>DH2650 Individual Development Blog</h2>
        {/*<p>Aliquam ut ex ut interdum donec amet imperdiet eleifend</p>*/}
      </header>
      <section className="wrapper style5">
        <div className="inner">

          <section>
            <h4>Final Presentation | 2022-05-24</h4>
            <p>
              It was very interesting to see what the other games created were and how they progressed throughout the semester. I am quite happy with my team's work.
              Now comes the final fixes for the game, website, and GDD before handing everything in...
            </p>
          </section>
          <hr />

          <section>
            <h4>2nd Demo Day | 2022-05-16</h4>
            <p>
              The feedback from other students were mostly small fixes, and since there is not a lot of time left we decided to just finish what we are currently working on without adding too many more things to the game.
              I will now focus my shift on to the GDD and website.
            </p>
          </section>
          <hr />

          <section>
            <h4>Finally got the energy system to work! | 2022-05-07</h4>
            <p>
              Met up with August on campus to get some work done and I finally got the basic energy system to work! 
              I'm starting to get a better understanding of how signals work in Godot, previously I was somewhat guessing but it ended up working anyway.
              I will now start to implement the extneded functionality of the energy system, such as using energy when performing various tasks and changing the energy system based on what curse is applied to the player.
            </p>
          </section>
          <hr />

          <section>
            <h4>Meeting after Easter Break | 2022-04-28</h4>
            <p>
              Updates from all team members about what was done over the break, and assigning next step tasks.
              My next step is to work on the player energy system, I will also work with Bianca to start the website.
            </p>
          </section>
          <hr />

          <section>
            <h4>First Demo and Feedback | 2022-04-08</h4>
            <p>
              We got lots of feedback about the game from fellow students. This feedback included ****more about feedback here from the doc
            </p>
          </section>
          <hr />

          <section>
            <h4>In Person Work Meeting | 2022-04-06</h4>
            <p>
              Met in person to do some work in the library, merged code on our various branches and discussed what we could complete for the demo in 2 days.
            </p>
          </section>
          <hr />

          <section>
            <h4>First Presentation | 2022-04-01</h4>
            <p>
              After the pitch presentation, we received some good feedback and questions to consider. 
              My notes include purchase plan, whether or not we will have combat, and the average playtime for one run.
              We met after the presentation to discuss next steps, we started to assign more specific tasks to each person and set up a project management system. 
              I was assigned to work on the destructible objects, which can include anything like the player, enemies, trees, etc.
            </p>
          </section>
          <hr />

          <section>
            <h4>Even More Game Discussion | 2022-03-30</h4>
            <p>
              We finally decide on a name for our game, Curse of Hau. We continue about how our game will be played, if there will be one island  with multiple biomes or several different islands. 
              I've noticed that some team members are very creative and have lots of ideas, but I don't think its possible to implement all those ideas based on our skillsets and timeframe.
              We also discuss the enemy system and start making the first pitch presentation.
            </p>
          </section>
          <hr />

          <section>
            <h4>Game Discussion | 2022-03-28</h4>
            <p>
              Settled on creating a roguelike survival game, had lots of brainstorming on core mechanics, gameplay, and other features.
              We also assigned roles based on what each team members strengths are.
              For the next meeting, we will each come up with some name ideas for the game. 
              My idea is Mobius Curse or Mobius Island to allude to the fact the player is cursed to be stuck on the island forever.
            </p>
          </section>
          <hr />
          
          <section>
            <h4>Fiddling around with Godot | 2022-03-26</h4>
            <p>
              Tried using Godot for the first time, quite interesting to learn how to use a game engine. 
              Followed a basic tutorial of creating a 2D game from the Godot website. 
              Came across some roadblocks, so I watched lots of YouTube tutorials on creating games in Godot.
            </p>
          </section>
          <hr />

          <section>
            <h4>First Team Meeting | 2022-03-23</h4>
            <p>
              Met with my group members for the first time today and did some brainstorming on what kind of games and genres we have in common.
              We also discussed our individual skills and what aspects of the project each of us wants to work on. 
              For the next meeting, we will all do research on currently existing games and pull from those.
            </p>
          </section>
          <hr />

          <section>
            <h4>Initial Post | 2022-03-21</h4>
            <p>
              Looking forward to learning lots during this project!
            </p>
          </section>

        </div>
      </section>
    </article>
  </Layout>
//);

export default IndexPage;
